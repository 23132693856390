import { ApiConfig } from "../config/apiConfig";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

export const UserContext = createContext();

export default function Notifiuser(props) {
  const [notificationList, setNotificationList] = useState([]);
  const [countNotification, setCountNotification] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState();
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });
  const [filter, setFilter] = useState({
    search: "",
    city: "",
    witlisted: false,
  });



  

  const getAllNotification = async () => {
    setIsDataLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios({
        method: "GET",
        url: `${ApiConfig.notifications}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response?.status === 200) {
        
        const notifications = response?.data?.ResponseBody?.notifications || [];
        setCountNotification(response?.data?.ResponseBody?.count || 0);
        setNotificationList(notifications);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsDataLoading(false);
    }
  };

  const getProfile = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios({
        url: ApiConfig.getProfileData,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response && response?.data?.responseCode === 200) {
      
        setProfileData(response?.data?.responseData);
        setUploadImage(response?.data?.responseData?.profile_picture);
      
        //   initialValues.fullName = response?.responseData?.fullName;
        //   initialValues.email = response?.responseData?.email;
        //   initialValues.contact = response?.responseData?.contact;
      } else {
      
        toast.error(response?.data?.responseMessage);
      }

      setIsLoading(false);
    } catch (err) {
  
      toast.error(err?.response?.responseMessage || "An error occurred");
      setIsLoading(false);
    }
  };
  const [doctorList, setDoctorList] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isData, setIsData] = useState(false);
  
  const getDoctorList = async (page, size) => {
    try {
      setIsLoading1(true);
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
      };
  
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      const res = await axios({
        method: "GET",
        url: ApiConfig["doctorLists"],
        headers,
        params: {
          ...filter,
          page,
          size,
        },
      });
  
      if (res.data?.ResponseCode === 200) {
       
        // // toast.success(res.data?.ResponseMessage);
        setDoctorList(res?.data?.ResponseBody);
        setIsLoading1(false);
        setIsData(true);
        setPaginationData((prevData) => ({
          ...prevData,
          page,
        }));
  
        // setSpecialization(res?.data?.responseData?.specialization)
        // return res.data;
      } else {
        setIsLoading1(false);
        // toast.error(res.data?.responseMessage || "Something went wrong");
        return null;
      }
    } catch (error) {
      setIsLoading1(false);
      toast.error(
        error?.response?.data?.ResponseMessage || "Something went wrong"
      );
      return null;
    }
  };
  const contextData = {
    notificationList,
    countNotification,
    setNotificationList,
    setNotificationList,
    setIsDataLoading,
    isDataLoading,
    getAllNotification,
    uploadImage,
    setUploadImage,
    profileData,
    setProfileData,
    isLoading,
    setIsLoading,
    getProfile, 
    doctorList,
    isLoading1,
    getDoctorList,
    setPaginationData,
    paginationData,
    filter,
    setFilter,
    isData,
    setIsData
  };

  return (
    <UserContext.Provider value={contextData}>
      {props.children}
    </UserContext.Provider>
  );
}
